@use "../../theme/sass/get-theme";

.view-image-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

.image-container-capture {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.display-img-captutre {
  height: 100%;
  width: 100%;
}
.display-img-upoload {
  height: auto;
  width: 92vw;
}
.button-container-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  background: white;
}
.button-container-capture {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: absolute;
  width: 100%;
  gap: 1.5rem;
  z-index: 3;
  bottom: 0;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.underline-text {
  @include get-theme.get-theme(color, color-primary);
  @include get-theme.get-theme(border-bottom, color-primary, 1px solid);
}
