@use "../../theme/sass/get-theme";

.camera-div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
}

.spinner-div {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
}
.camera-header {
  z-index: 2;
  display: flex;
  flex-direction: row;
  height: 3.25rem;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0;
  align-items: center;
  box-sizing: border-box;
}

.camera-close-btn {
  padding: 0.75rem;
  position: absolute;
  left: 0;
}

.close {
  width: 1.5rem;
  height: 1.5rem;
}
.flash-icon {
  width: 20px;
  height: 25px;
}
.camera-footer {
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 8.5rem;
  width: 100%;
  top: 0;
  align-items: center;
  padding: 0 0.5rem;
  box-sizing: border-box;
  @include get-theme.get-theme(background-color, background-tertiary);
}
.gallery-icon {
  width: 50px;
  height: 50px;
}
.flip-icon {
  width: 50px;
  height: 50px;
}
.camera-button {
  width: 100px;
  height: 100px;
}
.camera-guidance {
  z-index: 2;
  width: calc(100vw - 2rem);
  height: calc(100vw - 2rem);
}
.header-message {
  @include get-theme.get-theme(color, text-tertiary);
}
.choose-from-gallery-icon {
  input[type="file"] {
    display: none;
  }
}
